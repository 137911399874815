import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { strapiURL } from '../../../../config'
import { Form, Input, Select, message } from 'antd'
import { AttatchIcon } from '../../../icons'
import { navigate } from 'gatsby'
import { useSelector } from 'react-redux'
const ApplicationDetailComponent = ({ location }) => {
  let ReactQuill
  if (typeof window !== 'undefined') {
    ReactQuill = require('react-quill')
  }
  const [form] = Form.useForm()
  const { Option } = Select
  const [resume, setResume] = useState('')
  const [coverLetter, setCoverLetter] = useState('')
  const [submitloading, setSubmitLoading] = useState(false)
  const userProfile = useSelector(state => state.user.profileData)
  const [loading, setLoading] = useState(false)
  let applicationId = location?.pathname?.split('/')[6]
  let slug = location?.pathname?.split('/')[3]
  useEffect(() => {
    form.setFieldsValue({
      cover_letter: '',
    })
    setLoading(true)
    axios
      .get(strapiURL + `/applications/${applicationId}`)
      .then(response => {
        if (response?.data?.job?.employer !== userProfile?.id) {
          message.error('Application does not belongs to you.')
          navigate('/employer-dashboard')
          return
        }
        form.setFieldsValue({
          name:
            response?.data?.candidate_profile?.first_name +
            ' ' +
            response?.data?.candidate_profile?.last_name,
          email: response?.data?.userEmail || '',
          status: response?.data?.status || '',
          cover_letter: response?.data?.cover_letter?.text || '',
        })
        setResume(response.data.resume)
        setCoverLetter(response?.data?.cover_letter?.text)
        setLoading(false)
      })
      .catch(err => {
        message.error('Unable to open application.')
        navigate('/employer-dashboard')
        console.log(err)
        setLoading(false)
      })
  }, [])
  const onFinish = values => {
    setSubmitLoading(true)
    axios
      .put(strapiURL + `/applications/${applicationId}`, {
        status: values?.status,
      })
      .then(res => {
        message.success('Updated Successfully')
        setSubmitLoading(false)
      })
      .catch(err => {
        message.error('Something went wrong')
        setSubmitLoading(false)
      })
  }
  const viewResume = (base64, fileName) => {
    if (typeof window !== 'undefined') {
      let a = document.createElement('a')
      a.href = 'data:application/octet-stream;base64,' + base64
      a.download = fileName
      a.click()
      a.remove()
    }
  }

  return (
    <>
      {loading ? (
        <div
          className="w-5/5 lg:w-3/5 min-h-700px bg-cover bg-center bg-no-repeat mt-16"
          style={{
            backgroundImage: `url(/loaders/application-loader.png)`,
          }}
        />
      ) : (
        <div>
          <div className="flex justify-between items-end">
            <h1 className="font-playfair text-headingThree font-semibold text-merlot">
              Application Detail
            </h1>
          </div>
          <Form
            className="w-full lg:w-1/2"
            form={form}
            name="candidate-profile-form"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="name"
              label="Name"
              className="theme-form-item w-full mb-8"
            >
              <Input
                disabled
                type="text"
                placeholder="Enter Your Name"
                className="placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
              />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              className="theme-form-item w-full mb-8"
            >
              <Input
                disabled
                type="email"
                placeholder="Enter Your Name"
                className="placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
              />
            </Form.Item>
            <Form.Item
              name="status"
              label="Status"
              className="theme-form-item w-full mb-8"
            >
              <Select>
                <Option value="Active">Active</Option>
                <Option value="Rejected">Rejected</Option>
                <Option value="Contacted">Contacted</Option>
              </Select>
            </Form.Item>
            <h1 className="font-sans text-lg font-bold">Documents</h1>
            <div className="flex items-center mb-8">
              <AttatchIcon />
              <span
                onClick={() => viewResume(resume?.parsed_resume, resume?.name)}
                target="_blank"
                className="mb-0 mx-3 text-blue-500 cursor-pointer"
              >
                {resume.name}
              </span>
            </div>
            <div
              className="border p-4 bg-[#f5f5f5]"
              dangerouslySetInnerHTML={{
                __html: coverLetter,
              }}
            />
            <button
              type="submit"
              className="bg-voyage py-3 px-6 text-white font-bold rounded text-sm font-sans mr-4 mt-8"
            >
              {submitloading ? 'Updating...' : 'Update'}
            </button>
            <button
              type="button"
              className="bg-bgSecondaryButton text-voyage sm:w-auto text-sm font-bold font-sans py-3 px-6 rounded"
              onClick={() => {
                navigate(`/employer-dashboard/jobs/${slug}/applications/`)
              }}
            >
              Back
            </button>
          </Form>
        </div>
      )}
    </>
  )
}
export default ApplicationDetailComponent
