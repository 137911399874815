import React from 'react'
import ApplicationDetailComponent from '../../../../../../components/employerDashboard/jobs/applications/application'
import Layout from '../../../../../../components/employerDashboard/layout'
import { Router } from '@reach/router'
const ApplicationDetailPage = ({ location }) => {
  let applicationId = location?.pathname?.split('/')[6]
  let JobId = location?.pathname?.split('/')[3]
  return (
    <Layout title="Applications Detail | MedReps.com" location={location}>
      <Router basepath="/employer-dashboard/jobs">
        <ApplicationDetailComponent
          path={`/${JobId}/applications/application/${applicationId}`}
        />
      </Router>
    </Layout>
  )
}
export default ApplicationDetailPage
